<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <div class="ic-title">编辑用户推送</div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="推送方式" name="pushType" :rules="[{ required: true, message: '请选择推送方式' }]">
            <a-radio-group v-model:value="form.pushType">
              <a-radio value="manual">手动推送</a-radio>
              <a-radio value="fixedTime">定时推送</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item v-if="form.pushType == 'fixedTime'" label="推送时间" name="fixedTimePush" :rules="[{ required: true, message: '请选择推送时间' }]">
            <a-date-picker v-model:value="form.fixedTimePush" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm"
              placeholder="请选择签约时间" style="width: 100%" :disabled-date="disabledDate" 
              :getPopupContainer="triggerNode => { return triggerNode.parentNode || document.body; }" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="推送用户" name="userType" :rules="[{ required: true, message: '请选择推送用户' }]">
            <a-radio-group v-model:value="form.userType">
              <a-radio value="allUser">全部用户</a-radio>
              <a-radio value="assignUser">指定用户</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col v-if="form.userType == 'assignUser'" :span="12">
          <a-button @click="download('/api/message/manualPush/downloadTemplateByType?name=userPush')"
            type="link">下载模板</a-button>
          <a-upload v-model:file-list="fileList" :data="template" :before-upload="beforeUpload" name="file"
            action="/api/message/manualPush/uploadTemplateByType" :headers="headers" @change="handleChange">
            <a-button type="link">上传模板</a-button>
          </a-upload>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="消息类型" name="msgType" :rules="[{ required: true, message: '请选择消息类型' }]">
            <a-radio-group @change="changeMsg" v-model:value="form.msgType">
              <a-radio value="push">push</a-radio>
              <a-radio value="website">站内信</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="pushContent">
        <a-col :span="12">
          <a-form-item label="推送内容" name="messageTemplateId" :rules="[{ required: true, message: '推送内容' }]">
            <a-select v-model:value="form.messageTemplateId" @change="changeContent" placeholder="请选择推送内容"
              style="width: 100%" show-search :filter-option="filterSouOption">
              <a-select-option v-for="option in pushContent" :key="option.id" :text="option.title">{{ option.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="currContent" style="margin-bottom: 20px;">
        <a-col class="ma-col" :span="18">
          <div class="ms-flex">
            <span class="ms-title">消息标题：</span>
            <span class="ms-content">{{ currContent.title || '' }}</span>
          </div>
          <div v-if="form.msgType =='website'" class="ms-flex">
            <span class="ms-title">摘要：</span>
            <span class="ms-scroll">{{ currContent.summary || '' }}</span>
          </div>
          <div v-if="form.msgType =='push'" class="ms-flex">
            <span class="ms-title">消息内容：</span>
            <span class="ms-scroll">{{ currContent.content || '' }}</span>
          </div>
          <div v-else class="ms-flex">
            <span class="ms-title">消息内容：</span>
            <p class="ms-scroll" v-html="currContent.content"></p>
          </div>
          <div v-if="form.msgType =='push'" class="ms-flex">
            <span class="ms-title">落地页链接：</span>
            <span class="ms-content">{{ currContent.landUrl || '' }}</span>
          </div>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { citysMenus } from '@/city.js';
import { companyEntity, country, receptionCity } from '@/utils/public';
import dayjs from 'dayjs';
import { Upload } from 'ant-design-vue';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: { pushType: "manual", userType: 'allUser', msgType: 'push' },
      citys: citysMenus,
      companyEntity: companyEntity,
      country: country,
      receptionCity: receptionCity,
      openModal: false,
      showModal: false,
      modalTitle: '',
      modalContent: '',
      counting: false,
      countdown: 5,
      countdownInterval: null,
      startDisabled: false,
      isComfirm: false,
      domain: {},

      headers: {
        authorization: 'authorization-text',
      },
      fileList: [],
      openModal: false,
      result: {},
      pushContent: null,
      currContent: null,

      template: {
        id: '',
        name: 'userPush'
      }
    }
  },
  created() {
    this.getById();
  },
  methods: {
    disabledDate(time) {
      return time < Date.now();
    },

    changeMsg() {
      this.currContent = null;
      this.form.messageTemplateId = null;
      this.getMessageTemplateByType(this.form.msgType)
    },

    async getById() {
      try {
        const res = await this.$http.get('/message/manualPush/getById', {
          id: this.selectedRows[0].id
        });
        this.form = res.source;
        if(res.source.fixedTimePush) {
          this.form.fixedTimePush = dayjs(res.source.fixedTimePush);
        }
        this.template.id = res.source.id;
        this.$nextTick(()=>{
          this.getMessageTemplateByType(this.form.msgType);
        })
        
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getMessageTemplateByType(type) {
      try {
        const res = await this.$http.get('/message/manualPush/getMessageTemplateByType', {
          templateType: type,
          pushTo: 'userPush'
        });
        this.pushContent = res.data;
        this.template.id = res.id;
        if(this.form.messageTemplateId) {
          this.currContent = this.pushContent.filter(item => item.id == this.form.messageTemplateId)[0];
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },

    changeContent(e) {
      this.currContent = this.pushContent.filter(item => item.id == e)[0];
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.submitLoading = true;
      let fixedTimePush = null;
      if(this.form.fixedTimePush) {
        fixedTimePush = dayjs(this.form.fixedTimePush).format('YYYY-MM-DD HH:mm:ss')
      }
      let {id, pushType, userType, msgType, messageTemplateId } = this.form;
      // let id = this.form.userType == 'assignUser' ? this.template.id : null;
      let pushTo = 'userPush';
      try {
        await this.$http.post('/message/manualPush/edit', { pushTo, id, pushType, userType, msgType, messageTemplateId, fixedTimePush });
        this.$message.success('提交成功');
        this.$refs.form.resetFields();
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    async download(api) {
      let action = {
        url: api,
        newPage: true
      };
      let rows = [];
      const urlStr = action.url.replace(/{(\w+)}/g, (substr, group) => rows[0][group]);
      const url = new URL(/^https?:\/\//.test(urlStr) ? urlStr : window.location.origin + urlStr);
      if (action.newPage) {
        window.open(url.toString());
        this.$message.success('下载成功');
      } else {
        window.location.href = url.toString();
      }
    },

    // 格式验证
    beforeUpload(file) {
      if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.$message.error('上传文件格式错误');
        return false || Upload.LIST_IGNORE;
      }
    },

    handleChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        this.result = info.file.response;
        this.openModal = true;
        this.$message.success('上传模板成功');
      } else if (info.file.status === 'error') {
        this.$message.error('上传模板失败');
      }
    },
  }
}
</script>

<style scoped lang="scss">
.blueCopy {
  color: blue;
  text-decoration: underline;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

.ma-col {
  border: 1px solid #d9d9d9;
  padding: 20px 20px 0 20px;
  border-radius: 8px;

  .ms-flex {
    display: flex;
    margin-bottom: 20px;
  }

  .ms-title {
    width: 90px;
    text-align: right;

    flex-shrink: 0;
  }

  .ms-content {
    flex-shrink: 0;
    width: calc(100% - 130px);
  }

  .ms-scroll {
    flex-shrink: 0;
    width: calc(100% - 130px);
    max-height: 200px;
    overflow-y: scroll;
  }

  .ms-img {
    width: 160px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}
</style>
